import React, { useState, useEffect } from "react";
import Solari from "./Solari";

const displayItems = [
  "A developer",
  "A designer",
  "A wearer of many hats",
  "A language enthusiast",
  "A software engineer",
  "An artist",
  "An educator",
  "A creative technologist",
];

function SplitFlapTitle() {
  const [displayIndex, setDisplayIndex] = useState(1);
  const [prevIndex, setPrevIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => updateDisplay(), 5000);
  });

  const updateDisplay = () => {
    const newPrev = prevIndex >= displayItems.length - 1 ? 0 : prevIndex + 1;
    setPrevIndex(newPrev);
    const newDisplay =
      displayIndex >= displayItems.length - 1 ? 0 : displayIndex + 1;
    setDisplayIndex(newDisplay);
  };

  return (
    <Solari
      displayContent={displayItems[displayIndex]}
      prevDisplayContent={displayItems[prevIndex]}
      length={23}
      color="black"
    />
  );
}

export default SplitFlapTitle;
