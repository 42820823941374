import React from "react";
import "./App.css";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Home from "./components/Home";
// import Bio from "./components/Bio";
// import ProjectsPage from "./components/ProjectsPage";
// import Header from "./components/Header";
// import Footer from "./components/Footer";
import SplitFlapTitle from "./components/SplitFlapTitle";
import RecentWork from "./components/RecentWork";
import Container from "react-bootstrap/Container";
import styled from "styled-components";

const TopNav = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  background: inherit;
  background-opacity: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  a: {
    color: #212529;
  }
`;
const Pane = styled.div`
  padding-top: 12vh;
  background: ${(props) => (props.color ? props.color : "#fff")};
  min-height: 100vh;
  padding-bottom: 8vh;
`;
const NavLink = styled.a`
  background: inherit;
  color: ${(props) => (props.dark ? "#fff" : "#212529")};
  margin: 2px;
  outline: none;
  border: none;
  padding-top: 1.1em;
  padding-bottom: 0.9em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  a:visited {
    color: dark;
  }
  ${(props) =>
    props.light
      ? "background-color: #FFFFFF; :hover { background-color: #F9F5F1; } :active { backgound-color: #F3EBE4; }"
      : ""}
  ${(props) =>
    props.dark
      ? "background-color: #292B39; :hover { background-color: #3E404D; } :active { backgound-color: #545561; }"
      : ""}
`;
const Text = styled.span`
  color: ${(props) => (props.color ? props.color : "#212529")};
`;
const Title = styled.h1`
  font-size: 36px;
  letter-spacing: 6px;
`;

function App() {
  return (
    <div>
      <TopNav>
        <NavLink href="#home" light>
          <Text>home</Text>
        </NavLink>
        <NavLink href="#about" light>
          <Text>about</Text>
        </NavLink>
        <NavLink href="#work" light>
          <Text>work</Text>
        </NavLink>
      </TopNav>
      <Pane id="home" color="#ffe6ea">
        <Container>
          <Title>
            {/* <em>krista rutz</em> <span className="muted">is a</span> <br></br>
            software engineer,<br></br>designer,<br></br>and wearer of many
            hats. */}
            KRISTA RUTZ <em>is</em>
            <SplitFlapTitle />
          </Title>
        </Container>
      </Pane>
      <Pane id="about">
        <Container>
          <p className="lead">
            Krista Rutz is a software engineer and fullstack web developer from
            the Seattle area.
          </p>
          <p>
            Rutz studied International Relations at Pomona College in Claremont,
            California.{" "}
            {/* {With coursework in various departments including
            Computer Science, Linguistics, Art, Politics, and four human
            languages, Rutz took advantage of the liberal arts offerings to
            satisfy the broad intellectual curiosity that characterizes her work
            today.} In 2014, her team earned first
            place novice in the semi-annual 5C Hackathon for development of an
            alcohol safety mobile application. */}
            She was involved in AmeriCorps' Jumpstart for young children
            program, student government, and spent two years as a mentor in the
            international student community. Rutz also took the opportunity to
            study abroad in Germany and Turkey, completing her thesis on the
            dynamics of leverage in Turkey-EU relations. She spent the year
            following graduation as a Fulbright Scholar in Berlin, teaching
            English under Fulbright Germany's Diversity Program.
          </p>
          <p>
            During a period working as an assistant Home Stager for an interior
            redesign company, Rutz began to focus on software development. She
            taught herself through online coursework and attended an intensive
            six month coding bootcamp at Epicodus, Inc., becoming proficient in
            C# and JavaScript technology stacks.
          </p>
        </Container>
      </Pane>
      <Pane id="work" color="#292b39">
        <Container>
          <h2 className="display-4" style={{ color: "#fff" }}>
            Recent Projects
          </h2>
          <RecentWork />
        </Container>
      </Pane>
      <Pane>
        <Container>
          <div className="footer-col-container">
            <div className="footer-col">
              <p>&copy; Krista Rutz 2020</p>
            </div>
            <div className="footer-col">
              <p>
                <a
                  href="https://www.linkedin.com/in/kristarutz/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </p>
              <p>
                <a href="https://github.com/KristaRutz" target="_blank">
                  GitHub
                </a>
              </p>
            </div>
            <div className="footer-col">
              <p>
                <a
                  href="https://www.flickr.com/photos/krista_rutz/"
                  target="_blank"
                >
                  Flickr
                </a>
              </p>
              <p>
                <a href="https://cacerolear.wordpress.com/" target="_blank">
                  Blog
                </a>
              </p>
              <p>
                <a href="https://medium.com/@kristarutz" target="_blank">
                  Medium
                </a>
              </p>
            </div>
          </div>
        </Container>
      </Pane>
    </div>
  );
}

export default App;
