//import {v4} from uuid;

/*
CONSTANTS for TAGS
*/

// ** Project Status **
const USER_INTERFACE = "User Interface";
const DEPLOYED = "Deployed";
const LIVE_SITE = "Live Site";
const PRODUCTION_CODE = "Production Code";

// ** Project types **
const FRIDAY_PROJ = "Friday project";
const CAPSTONE = "Capstone";
const CLASS_PROJ = "In-class project";
const INDEPENDENT_PROJ = "Independent project";
const TEAM_PROJ = "Team project";
const OPEN_SOURCE = "Open source project";
const INTERNSHIP_PROJ = "Internship project";

// ** Workflow **
const PAIR = "Pair programming";
const MOB = "Mob programming";
const AGILE = "Agile methodology";
const CI_CD = "CI/CD";
const GH = "GitHub";
const GIT = "Git";
const CLI = "Command Line";

// ** Languages **
const JS = "JavaScript";
const CSHARP = "C#";
const PYTHON = "Python";
const JAVA = "Java";
const ES6 = "ES6";
const HTML = "HTML";
const CSS = "CSS";
const MD = "Markdown";
const RAZOR_MARKUP = "Razor Markup";
const JSX = "JSX";

// ** Programming paradigms **
const OOP = "Object-oriented Programming";
const FP = "Functional Programming";
const SQL = "SQL";
const NO_SQL = "NoSQL";
const TDD = "Test-driven Development";
const BDD = "Behavior-driven Development";

// ** Frameworks, SDKs, Libraries, & Runtime Environments **
// ** TECHNOLOGIES by Stack, Service type **
const NODE = "NodeJS";
const REACT = "React";
const REDUX = "Redux";
const JQUERY = "jQuery";
const REACT_NATIVE = "React Native";
const JEST = "Jest";

const DOTNETCORE = ".NET Core";
const ASPDOTNETCORE = "ASP.NET Core";

const DJANGO = "Django";

const FIREBASE = "Firebase";
const AZURE = "Azure";
const AWS = "AWS";
const DOCKER = "Docker";
const CMS = "CMS";
const WORDPRESS = "WordPress";

const FIRESTORE = "Cloud Firestore";
const MYSQL = "MySQL";
const MYSQL_SERVER = "MySQL Server";
const MYSQL_WORKBENCH = "MySQL Workbench";
const MS_SQL_SERVER = "Microsoft SQL Server";
const POSTGRES = "PostgreSQL";
const ORM = "ORM";
const ENTITY = "Entity Framework ORM";

const BOOTSTRAP = "Bootstrap";
const MATERIAL_UI = "Material UI";
const STYLED_COMPS = "Styles Components";

const FIREBASE_AUTH = "Firebase Authentication";
const MS_IDENTITY = "ASP.NET Core Identity";

// ** Applications **
const FEATURE = "Feature";
const TEST = "Test";
const BUG_FIX = "Bug fix";

const FRONT_END = "Front end";
const BACK_END = "Back end";
const FULL_STACK = "Full stack";

const DATABASE = "Database";
const CONSOLE_APP = "Console application";
const WEB_APP = "Web application";
const SINGLE_PAGE = "Single page web application";
const MVC = "MVC";
const API = "RESTful HTTP Web API";
const AUTH = "User Authentication";

const SERVERSIDE = "Server-side";
const CLIENTSIDE = "Client-side";

// ** Misc. **
const MAPBOX = "Mapbox SDK";
const QUILL = "QuillJS";
const SLATE = "SlateJS";

const TAGS = [
  USER_INTERFACE,
  DEPLOYED,
  LIVE_SITE,
  PRODUCTION_CODE,
  FRIDAY_PROJ,
  CAPSTONE,
  CLASS_PROJ,
  INDEPENDENT_PROJ,
  TEAM_PROJ,
  OPEN_SOURCE,
  INTERNSHIP_PROJ,
  PAIR,
  MOB,
  AGILE,
  CI_CD,
  GH,
  GIT,
  CLI,
  JS,
  CSHARP,
  PYTHON,
  JAVA,
  ES6,
  HTML,
  CSS,
  MD,
  RAZOR_MARKUP,
  JSX,
  OOP,
  FP,
  SQL,
  NO_SQL,
  TDD,
  BDD,
  NODE,
  REACT,
  REDUX,
  JQUERY,
  REACT_NATIVE,
  JEST,
  DOTNETCORE,
  ASPDOTNETCORE,
  DJANGO,
  FIREBASE,
  AZURE,
  AWS,
  DOCKER,
  CMS,
  WORDPRESS,
  FIRESTORE,
  MYSQL,
  MYSQL_SERVER,
  MYSQL_WORKBENCH,
  MS_SQL_SERVER,
  POSTGRES,
  ORM,
  ENTITY,
  BOOTSTRAP,
  MATERIAL_UI,
  STYLED_COMPS,
  FIREBASE_AUTH,
  MS_IDENTITY,
  FEATURE,
  TEST,
  BUG_FIX,
  FRONT_END,
  BACK_END,
  FULL_STACK,
  DATABASE,
  CONSOLE_APP,
  WEB_APP,
  SINGLE_PAGE,
  MVC,
  API,
  AUTH,
  SERVERSIDE,
  CLIENTSIDE,
  MAPBOX,
  QUILL,
  SLATE,
];

const projectData = {
  1: {
    id: 1,
    title: "StoryJam",
    tags: [
      USER_INTERFACE,
      DEPLOYED,
      LIVE_SITE,
      PRODUCTION_CODE,
      CAPSTONE,
      CLASS_PROJ,
      INDEPENDENT_PROJ,
      CI_CD,
      GH,
      GIT,
      CLI,
      JS,
      ES6,
      HTML,
      CSS,
      JSX,
      FP,
      NO_SQL,
      BDD,
      NODE,
      REACT,
      REDUX,
      FIREBASE,
      FIRESTORE,
      BOOTSTRAP,
      FIREBASE_AUTH,
      FRONT_END,
      BACK_END,
      FULL_STACK,
      DATABASE,
      WEB_APP,
      AUTH,
      CLIENTSIDE,
    ],
    description: "A React/Firebase application for collaborative writing",
    detailedDescription:
      "Based on the pen-and-paper Exquisite Corpse methodology, a group of writers, friends, or classmates can use StoryJam to test the limits of creative writing. In public or private 'rooms', players will add a few lines before passing the hidden story to the next player, with suprising and often humorous results. Authentication through email or Google account lets users save their creations. Krista built this with a React frontend and Firebase/JS backend.",
    liveLink: "https://story-jam.firebaseapp.com/",
    repo: "https://github.com/KristaRutz/story-jam",
    mainImage: "",
    languages: {
      javaScript: true,
      cSharp: false,
      java: false,
      css: true,
      html: true,
    },
  },
  2: {
    id: 2,
    title: "Taproom",
    tags: [
      USER_INTERFACE,
      DEPLOYED,
      LIVE_SITE,
      FRIDAY_PROJ,
      INDEPENDENT_PROJ,
      GH,
      GIT,
      CLI,
      JS,
      ES6,
      HTML,
      CSS,
      JSX,
      FP,
      BDD,
      NODE,
      REACT,
      REDUX,
      BOOTSTRAP,
      FRONT_END,
      WEB_APP,
      SINGLE_PAGE,
      CLIENTSIDE,
    ],
    description:
      "An app for a mythical taproom to track their kegs with react/redux",
    liveLink: "https://taproom-app.netlify.app/",
    repo: "https://github.com/KristaRutz/taproom",
    mainImage: "src/img/cloud2.svg",
    languages: {
      javaScript: true,
      cSharp: false,
      java: false,
      css: true,
      html: true,
    },
  },
  3: {
    id: 3,
    title: "Zine Maker",
    tags: [
      USER_INTERFACE,
      CLASS_PROJ,
      TEAM_PROJ,
      PAIR,
      AGILE,
      CI_CD,
      GH,
      GIT,
      CLI,
      JS,
      CSHARP,
      HTML,
      CSS,
      RAZOR_MARKUP,
      OOP,
      SQL,
      BDD,
      DOTNETCORE,
      ASPDOTNETCORE,
      MYSQL,
      MYSQL_SERVER,
      MYSQL_WORKBENCH,
      MS_SQL_SERVER,
      ORM,
      ENTITY,
      BOOTSTRAP,
      MS_IDENTITY,
      FRONT_END,
      BACK_END,
      FULL_STACK,
      DATABASE,
      WEB_APP,
      MVC,
      AUTH,
      SERVERSIDE,
      CLIENTSIDE,
      QUILL,
    ],
    description: "Turn content-rich posts into readable publications",
    detailedDescription:
      "Working as both senior developer and product owner on my team, Krista managed the development of a social media platform designed for collaboration. With a SQL server backend, complete user authentication and authorization built on Microsoft's ASP .NET Core Identity offerings, and a clean, modern looking UI resembling Medium, Zine Maker encourages organizations to create digital publications as a collection of contributions from member-users, utilizing Quill.JS rich text editor and photographs to create articles.",
    liveLink: "/#",
    repo: "https://github.com/ZineMaker/ZineClient.Solution",
    mainImage: "",
    languages: {
      javaScript: true,
      cSharp: true,
      java: false,
      css: true,
      html: true,
    },
  },
  4: {
    id: 4,
    title: "Beep Boop",
    tags: [
      USER_INTERFACE,
      DEPLOYED,
      LIVE_SITE,
      PRODUCTION_CODE,
      FRIDAY_PROJ,
      CAPSTONE,
      CLASS_PROJ,
      INDEPENDENT_PROJ,
      TEAM_PROJ,
      OPEN_SOURCE,
      INTERNSHIP_PROJ,
      PAIR,
      MOB,
      AGILE,
      CI_CD,
      GH,
      GIT,
      CLI,
      JS,
      CSHARP,
      PYTHON,
      JAVA,
      ES6,
      HTML,
      CSS,
      MD,
      RAZOR_MARKUP,
      JSX,
      OOP,
      FP,
      SQL,
      NO_SQL,
      TDD,
      BDD,
      NODE,
      REACT,
      REDUX,
      JQUERY,
      REACT_NATIVE,
      JEST,
      DOTNETCORE,
      ASPDOTNETCORE,
      DJANGO,
      FIREBASE,
      AZURE,
      AWS,
      DOCKER,
      CMS,
      WORDPRESS,
      FIRESTORE,
      MYSQL,
      MYSQL_SERVER,
      MYSQL_WORKBENCH,
      MS_SQL_SERVER,
      POSTGRES,
      ORM,
      ENTITY,
      BOOTSTRAP,
      MATERIAL_UI,
      STYLED_COMPS,
      FIREBASE_AUTH,
      MS_IDENTITY,
      FEATURE,
      TEST,
      BUG_FIX,
      FRONT_END,
      BACK_END,
      FULL_STACK,
      DATABASE,
      CONSOLE_APP,
      WEB_APP,
      SINGLE_PAGE,
      MVC,
      API,
      AUTH,
      SERVERSIDE,
      CLIENTSIDE,
      MAPBOX,
      QUILL,
      SLATE,
    ],
    description:
      "A robot-themed fizz-buzz browser game designed with simplicity in mind",
    liveLink: "https://kristarutz.github.io/Beep-Boop/",
    repo: "https://github.com/KristaRutz/Beep-Boop",
    mainImage: "",
    languages: {
      javaScript: true,
      cSharp: false,
      java: false,
      css: true,
      html: true,
    },
  },
  5: {
    id: 5,
    title: "Pandemic",
    tags: [
      USER_INTERFACE,
      DEPLOYED,
      LIVE_SITE,
      PRODUCTION_CODE,
      FRIDAY_PROJ,
      CAPSTONE,
      CLASS_PROJ,
      INDEPENDENT_PROJ,
      TEAM_PROJ,
      OPEN_SOURCE,
      INTERNSHIP_PROJ,
      PAIR,
      MOB,
      AGILE,
      CI_CD,
      GH,
      GIT,
      CLI,
      JS,
      CSHARP,
      PYTHON,
      JAVA,
      ES6,
      HTML,
      CSS,
      MD,
      RAZOR_MARKUP,
      JSX,
      OOP,
      FP,
      SQL,
      NO_SQL,
      TDD,
      BDD,
      NODE,
      REACT,
      REDUX,
      JQUERY,
      REACT_NATIVE,
      JEST,
      DOTNETCORE,
      ASPDOTNETCORE,
      DJANGO,
      FIREBASE,
      AZURE,
      AWS,
      DOCKER,
      CMS,
      WORDPRESS,
      FIRESTORE,
      MYSQL,
      MYSQL_SERVER,
      MYSQL_WORKBENCH,
      MS_SQL_SERVER,
      POSTGRES,
      ORM,
      ENTITY,
      BOOTSTRAP,
      MATERIAL_UI,
      STYLED_COMPS,
      FIREBASE_AUTH,
      MS_IDENTITY,
      FEATURE,
      TEST,
      BUG_FIX,
      FRONT_END,
      BACK_END,
      FULL_STACK,
      DATABASE,
      CONSOLE_APP,
      WEB_APP,
      SINGLE_PAGE,
      MVC,
      API,
      AUTH,
      SERVERSIDE,
      CLIENTSIDE,
      MAPBOX,
      QUILL,
      SLATE,
    ],
    description:
      "This digital twist on the board game uses satellite maps to help you save the world",
    detailedDescription:
      "Utilizing the MapBox SDK, this version of Pandemic, modeled after the board game, was created with a small team of students to connect the game mechanics of stopping a virus with the resources of modern mapping data. As team lead, Krista worked on integrating back and frontend development tasks, performing code reviews, and merging pull requests, as well as coding a significant portion of the vanilla JS application.",
    liveLink: "/#",
    repo: "https://github.com/epicodus-pandemic/Team-Pandemic",
    mainImage: "",
    languages: {
      javaScript: true,
      cSharp: false,
      java: false,
      css: true,
      html: true,
    },
  },
  6: {
    id: 6,
    title: "National Parks API",
    tags: [
      CLASS_PROJ,
      TEAM_PROJ,
      PAIR,
      AGILE,
      CI_CD,
      GH,
      GIT,
      SQL,
      CLI,
      CSHARP,
      BDD,
      DOTNETCORE,
      ASPDOTNETCORE,
      MYSQL,
      MYSQL_SERVER,
      MYSQL_WORKBENCH,
      MS_SQL_SERVER,
      ORM,
      ENTITY,
      BACK_END,
      DATABASE,
      SERVERSIDE,
    ],
    description:
      "Get and post data about national parks in the United States during uncertain times",
    liveLink: "/#",
    repo: "https://github.com/KristaRutz/National_Parks_API",
    mainImage: "",
    languages: {
      javaScript: false,
      cSharp: true,
      java: false,
      css: false,
      html: false,
    },
  },
};

export default projectData;
