import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SolariLayout = styled.div`
  background-color: ${(props) => `${props.color}80`};
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 2px;
  padding: 2px;
  width: auto;
  float: left;
`;
const Flap = styled.div`
  background-color: ${(props) => props.color};
  height: 2em;
  width: 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;

  color: white;
  // font: Helvetica;
  font-size: 24px;
  // font-weight: bold;
  letter-spacing: 0px;
`;

const FlapDiv = styled.div`
  display: inline;
`;

const FlapChar = styled.span``;

const SwitchFlap = ({ char = " ", prevChar = " ", color }) => {
  const chars = [
    "?",
    "",
    " ",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    ",",
    '"',
    "'",
    ":",
    ";",
    "/",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "-",
    "+",
    "<",
    ">",
  ];

  const prevCharIndexInitial = chars.indexOf(prevChar.toUpperCase());
  const charIndexInitial = chars.indexOf(char.toUpperCase());

  // ensure characters are in chars array, otherwise set them to question mark
  const prevCharIndex = prevCharIndexInitial > 0 ? prevCharIndexInitial : 0;
  const charIndex = charIndexInitial > 0 ? charIndexInitial : 0;

  const [counter, setCounter] = useState(prevCharIndex);

  useEffect(() => {
    counter != charIndex &&
      setTimeout(
        () => setCounter(counter < chars.length - 1 ? counter + 1 : 0),
        50
      );
  }, [counter]);

  return (
    <FlapDiv>
      <Flap color={color}>
        <FlapChar>{chars[counter]}</FlapChar>
      </Flap>
    </FlapDiv>
  );
};

const Solari = ({
  displayItems,
  prevDisplayContent,
  displayContent,
  length = 12,
  color = "#000000",
}) => {
  const displayChars = displayContent.split("");
  const prevChars = prevDisplayContent.split("");
  const displayArr = [];
  for (let i = 0; i < length; i++) {
    const initialChar = prevChars[i] ? prevChars[i] : undefined;
    const desiredChar = displayChars[i] ? displayChars[i] : undefined;
    displayArr.push([initialChar, desiredChar]);
  }
  console.log(color);
  return (
    <SolariLayout color={color}>
      {displayArr.map((char, index) => {
        return (
          <SwitchFlap
            color={color}
            key={index + char}
            prevChar={char[0]}
            char={char[1]}
          />
        );
      })}
    </SolariLayout>
  );
};

export default Solari;
