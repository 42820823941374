import React from "react";
import styled from "styled-components";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import ProjectCard from "./ProjectCard";

import projectData from "../portfolio-project-data";

const StyledDiv = styled.div`
  margin-top: 8vh;
  color: #fff;
  background-color: #292b39;
`;
const StyledCol = styled(Col)`
  display: flex;
  align-items: start;
`;

function RecentWork() {
  return (
    <StyledDiv>
      <Tab.Container defaultActiveKey="second">
        <Row>
          <StyledCol sm={3}>
            <Nav variant="moderno" className="flex-column">
              {/* <Nav.Item>
                <Nav.Link eventKey="first">
                  <span>EchoEcho App</span>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="second">
                  <span>StoryJam Game</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">
                  <span>Zine Maker</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">
                  <span>Pandemic</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </StyledCol>
          <StyledCol sm={9}>
            <Tab.Content>
              {/* <Tab.Pane eventKey="first">
                <ProjectCard project={projectData[2]} key={2} />
              </Tab.Pane> */}
              <Tab.Pane eventKey="second">
                <ProjectCard project={projectData[1]} key={1} />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <ProjectCard project={projectData[3]} key={3} />
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <ProjectCard project={projectData[5]} key={5} />
              </Tab.Pane>
            </Tab.Content>
          </StyledCol>
        </Row>
      </Tab.Container>
    </StyledDiv>
  );
}

export default RecentWork;
