import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Nav from "react-bootstrap/Nav";
import { GoMarkGithub, GoLinkExternal } from "react-icons/go";

import IconButton from "@material-ui/core/IconButton";

const Layout = styled.div`
  padding: 8px 16px;
`;
const StyledLinks = styled(Nav)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > a {
    background: #36384a;
    padding-bottom: 0px;
    margin: 2px;
  }
`;

function ProjectCard(props) {
  const { project } = props;
  return (
    <Layout>
      <h3>{project.title}</h3>
      <hr></hr>
      <p className="lead">{project.description.toLowerCase()}</p>{" "}
      <p>{project.detailedDescription}</p>
      <p className="muted">
        Main language: {project.languages.cSharp ? "C#" : "JavaScript"}
      </p>
      {/* <img title="project main image" image={project.mainImage} /> */}
      <StyledLinks variant="moderno">
        <OverlayTrigger
          overlay={
            <Tooltip
              id="button-tooltip"
              placement="top"
              delay={{ show: 400, hide: 400 }}
            >
              Go to project
            </Tooltip>
          }
        >
          <IconButton
            variant="contained"
            href={project.liveLink}
            target="_blank"
          >
            <GoLinkExternal />
          </IconButton>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip
              id="button-tooltip"
              placement="top"
              delay={{ show: 400, hide: 400 }}
            >
              View code on GitHub
            </Tooltip>
          }
        >
          <IconButton href={project.repo} target="_blank">
            <GoMarkGithub />
          </IconButton>
        </OverlayTrigger>
      </StyledLinks>
    </Layout>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.object,
};

export default ProjectCard;
